@import 'stylesheets/variables';
li.premade-reports-item.divider {
    @extend %body-3-black-600;
    border-top: none !important;
    padding: $nano 0 !important;
    flex-flow: column;
    position: relative;
    height: auto !important;
    min-height: $standard !important;

    span {
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
    }
}
