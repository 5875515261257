@import 'stylesheets/variables';

.source {
    border-bottom: $border;
    padding-bottom: $standard;
    .dataset-title {
        @extend %body-1-black-900;
        &:not(:first-of-type) {
            padding-top: $standard;
        }
    }

    .notes-title {
        @extend %body-2b-black-700;
        padding-top: $tiny;
    }

    .notes-content {
        @extend %body-2-black-700;
        padding-top: $micro;
        p {
            margin: unset;
        }
    }
}
