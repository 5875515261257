@import 'stylesheets/variables';

.change-survey-difference-message + .surface-1 {
    margin-bottom: $small;
    & + .surface-1 {
        margin-bottom: $small;
    }
}

.change-survey-collapse-info-section-heading {
    margin: $small $tiny;
}

.change-survey-collapse {
    margin-bottom: $small;
}

.warning-container {
    margin-bottom: $small;
}
