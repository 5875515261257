@import 'stylesheets/variables';
.modify-survey-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $black-100;
    border-radius: $border-radius-large;
    border: 1px solid $black-200;
    min-width: $sidebar-panel-dropdown-trigger-width;

    padding: $micro $small;
    &-selected-years {
        @extend %body-2-black-900;
        font-weight: 600;
    }

    &:hover {
        background-color: $black-250;
    }
}
