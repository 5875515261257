@import 'stylesheets/variables';

.existing-template {
    padding: 0 $standard $standard $standard;
    display: flex;
    flex-flow: column;
    align-items: center;

    i {
        width: $standard;
        margin-top: $large;
    }
    h2 {
        @extend %h4-black-900;
        margin: $semi 0;
    }
    p {
        @extend %body-1-black-900;
        margin-bottom: $standard;
    }

    button,
    a.go-to-dashboard {
        width: 100%;
        margin-top: $x-large;
    }
}
