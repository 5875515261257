@import 'stylesheets/variables';

.modify-template-tables {
    .modify-template-tables-toggle {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        margin-top: $tiny;

        &.expanded {
            justify-content: space-between;
        }

        h2 {
            @extend %h4-black-900;
            margin: 0;
            padding-bottom: $tiny;
            display: flex;
            align-items: center;
        }
    }

    .modes-selector-group {
        margin-top: 0;
        margin-bottom: $semi;
    }
}
