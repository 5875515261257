@import 'stylesheets/variables';
.survey-group {
    margin-top: $semi;
    .description {
        padding: $standard $standard;
        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .button {
                color: $black-600;
                text-decoration: underline;
                line-height: 18px;
            }
        }
        p {
            @extend %body-1-black-700;
            margin-top: $tiny;
        }
    }
}
