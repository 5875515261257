@import 'stylesheets/variables';

#share-panel {
    padding: $standard;
    .link-to-share {
        @extend %body-1-black-900;

        height: $standard;
        margin-bottom: $tiny;
    }

    .link-box {
        background: $black-100;
        border-radius: $nano;
        height: $x-large;
        padding: $small;
        word-break: break-all;

        @extend %body-3-black-900;
    }

    .copy-link-button-container {
        display: flex;
        justify-content: flex-end;

        .copy-link-button {
            margin-top: $tiny;
            color: $primary-800;
        }
    }

    .visualization-box {
        padding-top: 200px;
        text-align: center;
    }
}
