@import 'stylesheets/variables';

.upgrade-plan-banner {
    position: absolute;
    z-index: 4;
    height: calc(100% - (#{$header-height} + #{$nano}));
    width: calc(100% - #{$sidebar-collapsed-width});
    left: 0;
    padding: $upgrade-padding;
    background: $upgrade-gradient;
    &.shrinked-by-sidebar-width {
        width: calc(100% - #{$sidebar-expanded-width});
    }

    &-card {
        display: flex;
        flex-flow: column;
        width: 800px;
        margin: 0 auto;

        &-inner {
            @extend %body-1-black-600;
            border-radius: $micro;
            height: ($x-large * 4);
            text-align: center;

            h1 {
                margin-top: $large;
            }

            .text {
                margin: $small 0 ($semi + $nano) 0;
            }
        }
    }
}
