@import 'stylesheets/variables';

#download-csv-header-info {
    position: relative;

    .info-button {
        position: absolute;
        padding: 0.5 * $nano;
        margin: 0.5 * $nano;
        bottom: 0;
        left: 0.5 * $nano;

        &:hover {
            background-color: $black-200;
            color: $primary-800;
        }
    }

    .selected {
        background-color: $black-200;
        color: $primary-800;
    }
}

.info-popover {
    width: 320px;
    padding: $standard;
    border-radius: $micro;
    top: $large + $micro;
    left: -$large;
    z-index: 1000;
    color: $black-700;
    font-size: 14px;
    line-height: 22px;
    user-select: none;

    .zoomable {
        display: block;
    }
}
