@import 'stylesheets/variables';

#info-panel {
    @extend %body-1-black-700;
    padding: $standard;

    .source {
        margin-bottom: $standard;
    }

    .table-name-changes {
        margin-top: $tiny;
    }
}
