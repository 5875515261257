@import 'stylesheets/variables';

#download-csv-panel {
    @extend %body-2-black-700;
    padding: 0 $standard;

    .note {
        padding: $standard 0;
    }

    .description {
        padding-bottom: $standard;
    }

    .labeled-checkboxes-list {
        padding: $micro;
    }

    h5 {
        margin: $semi 0 $small;
    }
}
