@import 'stylesheets/variables';
.geo-items-selection-item {
    button {
        justify-content: space-between;
        align-items: center;
        &:hover,
        &:focus {
            .geo-items-selection-item-arrow {
                visibility: visible;
                color: $black-600;
            }
        }
    }
    &.selected {
        button {
            .geo-items-selection-item-check {
                visibility: visible;
                color: $primary-800;
            }
            .geo-items-selection-item-arrow {
                display: none;
            }
        }
    }
}

.geo-items-selection-item-label {
    white-space: normal;
    word-break: break-word;
    text-align: left;
}

.geo-items-selection-item-arrow {
    visibility: hidden;
}

.geo-items-selection-item-check {
    visibility: hidden;
}
