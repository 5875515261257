@import 'stylesheets/variables';
.dropdown-list-item {
    @extend %body-1-black-900;
    width: 100%;
    cursor: pointer;

    &:hover,
    &:focus {
        background: $primary-50;
    }
    &.selected {
        background: $primary-100;
    }

    .dropdown-list-item-button {
        padding: $micro 0 $micro $micro;
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
    }
}
