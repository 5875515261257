@import 'stylesheets/variables';
.help-button {
    margin-right: $small;
    margin-left: $micro;
    width: $jump-button-width;
    height: $jump-button-width;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.button-icon {
        color: $primary-800;
    }

    &:hover,
    &:focus {
        &.flat.button-icon {
            color: $primary-800;
            background-color: $black-100;
        }
    }
}
