@import 'stylesheets/variables';
.download-data-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding-right: 0;
    @extend %button-small-primary;
    &:disabled {
        pointer-events: none;
        @extend %button-small-black;
    }
    &:not(:first-child) {
        border-left: $border;
    }

    .icon {
        margin-right: $micro;
    }
}
