@import 'stylesheets/variables.scss';

.modify-survey {
    display: flex;
    justify-content: space-between;

    &-title {
        display: flex;
        flex-direction: column;
        span {
            @extend %body-2-black-600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // substract the sidebar panel padding, the trigger width
            // and additional $semi space to let the component breathe
            max-width: $sidebar-panel-width -
                $sidebar-panel-dropdown-trigger-width - 2 * $standard - $semi;
        }
    }
}
