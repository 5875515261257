@import 'stylesheets/variables';
@import 'stylesheets/button-reset';

.collapse-info-trigger {
    // reset button styles
    @extend %button-reset;
    cursor: pointer;

    display: flex;
    justify-content: space-between;

    width: 100%;
    padding: $tiny;

    &.open {
        border-bottom: $border;
    }
    &-left {
        display: flex;
        align-items: center;
        text-align: left;

        &-text {
            margin: 0 $tiny;
            &-heading {
                margin-bottom: $nano;
            }
        }
    }
    &-right {
        display: flex;
        align-items: center;
        @extend %body-1-black-700;
    }
}
