@import 'stylesheets/variables';
.fips-codes-geo-types-list {
    &-item {
        button {
            padding: $tiny $small;
            text-align: left;
            white-space: normal;
            word-break: break-word;
        }
    }
    .label {
        display: flex;
        max-width: 100%;
        &-sumlev {
            padding-right: $micro;
            white-space: nowrap;
        }
        &-indent {
            white-space: nowrap;
            padding-right: $micro;
        }
        &-body {
            white-space: normal;
            word-break: break-word;
            text-align: left;
        }
    }
}
