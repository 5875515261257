@import 'stylesheets/variables';

.geographies-modal-header {
    display: flex;
    flex-direction: column;
    padding: $small $tiny;

    .selection-counter {
        padding-left: $tiny;
        &.offset {
            padding-left: $micro;
            margin-left: $semi;
        }
    }

    .geographies-modal-header-title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .geographies-modal-header-title-wrapper {
            display: flex;
            align-items: center;

            .geographies-modal-header-title {
                @extend %h4-black-900;
                padding-left: $tiny;
                margin: 0;
            }
        }
    }
}
