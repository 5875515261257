@import 'stylesheets/variables';
.summary-level-download {
    border-radius: $nano;
    width: 100%;
    display: flex;
    flex-flow: column;
    .description {
        box-sizing: border-box;
        .description-item {
            display: flex;
            flex-flow: row;
            margin-bottom: $tiny;

            &:last-child {
                margin-bottom: 0;
            }

            .item-title {
                width: 120px;
                @extend %body-3-black-600;
            }
            .item-value {
                width: 160px;
                @extend %body-3-black-900;
            }
        }
    }
}
