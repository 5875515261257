@import 'stylesheets/variables.scss';
.surface {
    &-1 {
        border-radius: $nano;
        border: $border-black-300;
    }
    &-2 {
        border-radius: $nano;
        box-shadow: $box-shadow-surface-2;
    }
    &-3 {
        border-radius: $nano;
        box-shadow: $box-shadow-surface-3;
    }

    &-white {
        background: $white;
    }
    &-gray {
        background: $black-100;
    }
    &-orange {
        background: $warning-background;
        border: none;
    }
    &-green {
        background: $primary-100;
        border: none;
    }
}
