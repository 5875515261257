@import 'stylesheets/variables';

.sidebar-icon-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 72px;

    &.modify-report-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(205, 115, 113, 0.12);
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(205, 115, 113);
        }
    }
    &.download-excel-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(168, 189, 105, 0.12);
            }
            svg {
                margin-left: 3px;
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(168, 189, 105);
        }
    }
    &.download-csv-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(118, 173, 192, 0.12);
            }
            svg {
                margin: 2px 0px 0px 4px;
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(118, 173, 192);
        }
    }
    &.download-pdf-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(68, 126, 211, 0.12);
            }
            svg {
                margin: 2px 0px 0px 4px;
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(68, 126, 211);
        }
    }
    &.share-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(113, 132, 146, 0.12);
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(113, 132, 146);
        }
    }
    &.info-icon {
        .sidebar-icon-button {
            &:hover {
                background: rgba(239, 165, 75, 0.12);
            }
        }
        .sidebar-active-tab-indicator {
            background: rgb(239, 165, 75);
        }
    }

    .sidebar-icon {
        height: $x-large;
        width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sidebar-icon-button {
            width: 40px;
            height: 40px;
            padding: 0;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}

.sidebar-active-tab-indicator {
    width: 3px;
    height: 40px;
    border-radius: 3px 0px 0px 3px;
}

.sidebar-active-tab-indicator-not-active {
    width: 3px;
    height: 40px;
    border-radius: 3px 0px 0px 3px;
    background: transparent;
}
