@import '../../../stylesheets/variables';

.menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    .menu-list-item-button {
        padding-right: $standard;
        padding-left: $standard;
        width: 100%;
        justify-content: flex-start;
        @extend %body-1-black-900;
        i {
            margin-right: $small;
            color: $black-500;
        }
    }
    .menu-subtitle {
        @extend %body-3-black-600;
        padding: $micro $standard;
    }
}
