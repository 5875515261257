@import 'stylesheets/variables';

.report-navigator-container {
    position: absolute;
    bottom: $small;
    height: $navigator-height;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-right: $sidebar-collapsed-width;

    &.shrinked-by-sidebar-width {
        padding-right: $sidebar-expanded-width;
    }

    #report-navigator {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        height: $navigator-height;
        max-width: $navigator-width;
        width: 100%;

        background: white;
        border-radius: $nano;
        box-shadow: 0 ($nano * 0.5) $micro 0 rgba(96, 96, 96, 0.2);
        margin-bottom: $small;
        padding: $tiny 25px;
        z-index: 2;

        .simplified-navigator {
            @extend %body-2-black-700;
            display: flex;
            flex-flow: row;

            .item-amount {
                padding-left: $tiny;
            }
        }

        .navigator-popover {
            @extend %body-1-black-900;
            width: unset;
            padding: $standard;
            bottom: 54px;
            right: -25px;
        }

        .navigator-popover-content {
            display: flex;
            flex-flow: column;

            .title {
                font-weight: 600;
            }

            .navigator-popover-content-row {
                display: flex;
                flex-flow: row;
                align-items: center;
                .label {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: $standard;
                    flex-grow: 1;
                    white-space: nowrap;
                }

                &:not(:first-of-type) {
                    margin-top: $small;
                }

                .placeholder {
                    width: 122px;
                }
            }

            .dropdown-menu {
                display: flex;

                .dropdown-trigger {
                    @extend %body-1-black-900;
                    padding: 7px 10px;
                    min-width: 122px;
                    display: flex;
                    justify-content: space-between;

                    background-color: $black-100;
                    border: 1px solid $black-200;
                    border-radius: $nano;
                    line-height: $small;

                    .trigger-icon {
                        color: $black-900;
                        font-size: $icon-size-small;
                    }
                }

                .dropdown-menu-popover {
                    width: 122px;
                    bottom: 42px;

                    ul {
                        li {
                            button {
                                @extend %body-2-black-900;
                                padding: $nano 10px;
                            }
                            &:first-of-type {
                                margin-top: $nano;
                            }
                            &:last-of-type {
                                margin-bottom: $nano;
                            }
                        }
                    }
                }
            }
        }

        .navigator-popover-trigger {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $navigator-popover-button-width;
            height: $navigator-popover-button-width;
            color: $black-600;
            background-color: white;
            &:hover,
            &:focus {
                background-color: $black-100;
            }
        }

        .detailed-navigator {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .with-divider {
                display: flex;
                align-items: center;
                .vertical-divider {
                    height: 28px;
                }
            }

            .vertical-divider {
                margin: 0 18px;
            }

            .toggle-button {
                height: $semi;
                min-width: 113px;
            }
        }
    }
}
