@import 'stylesheets/variables';
.geographies-selection {
    position: relative;
    margin: $standard auto;
}

.geographies-selection-count {
    position: absolute;
    @extend %body-3-black-600;
    display: flex;
    align-items: center;
    right: 0;
}

.geographies-selection-divider {
    width: 1px;
    background-color: $black-300;
    margin-left: $small;
    height: $small;
}

.selection-separator {
    @extend %body-3-black-700;
    display: flex;
    align-items: center;
    padding: $standard $small $tiny $small;
}

.selection-group {
    padding: 0;
    margin: 0;
    .selected-list-item {
        &:first-child {
            border-radius: $nano $nano 0 0;
        }
        &:last-child {
            border-radius: 0 0 $nano $nano;
        }
        &:not(:last-child) {
            border-bottom: $border;
        }
    }
}
