@import 'stylesheets/variables';
.table-selection-item {
    button {
        justify-content: space-between;
        align-items: center;
        white-space: normal;
        word-break: break-word;
        text-align: left;

        .table-selection-list-item-arrow {
            color: $primary-800;
            visibility: hidden;
        }
    }
    &.selected {
        i.table-selection-list-item-arrow {
            visibility: visible;
        }
    }
}
