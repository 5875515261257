@import 'stylesheets/variables';

.compare-hint {
    display: flex;
    margin-top: $semi;
    padding-right: $tiny;

    .icon-chart {
        flex-shrink: 0;
    }
    .icon-right-arrow {
        width: 19px;
        height: 45px;
        flex-shrink: 0;
        transform: scale(-1, 1);
        margin-top: -$small;
    }
    &-text {
        @extend %body-1-black-900;
        font-size: 14px;
        margin-left: $small;
        margin-right: $small;
    }
}
