@import 'stylesheets/variables';

.surveys-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.surveys-list-item-section {
    display: flex;
    color: $black-900;

    .material-icons {
        color: $black-600;
    }
}
