@import 'stylesheets/variables';

.select-years-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $micro;
    margin-left: $standard;
    margin-right: $micro;
    margin-bottom: $standard;
}
