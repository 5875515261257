@import 'stylesheets/variables';
.header-back-button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 24px;
    background: white;
    color: $primary-800;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}
