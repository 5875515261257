@import 'stylesheets/variables';

.report-table {
    overflow: auto;
    max-height: 100%;
    margin-left: $small;
    margin-bottom: $x-large + $standard;
    position: relative;
    width: 100%;
    @extend %body-1-black-900;
    table {
        border-spacing: 0;
        margin-top: $micro;
        border-collapse: separate;
        table-layout: fixed;
        thead {
            position: relative;
            th {
                @extend %body-2-black-900;
                font-weight: bold;
                text-align: left;
                background-color: $table-header-background;
                border-spacing: 0;
                border: none;
                // if padding is changed, please update REPORT_TABLE_MARGIN_LEFT
                // in TableHeader component
                padding: $tiny $small;
                &:not(:last-of-type) {
                    border-right: $table-border-invert;
                }
                &:first-of-type {
                    position: sticky;
                    left: 0;
                    z-index: 3;
                    border-right: 2px $white solid;
                }
                position: sticky;
                z-index: 2;
                top: 0px;
            }
        }
        tbody {
            td,
            th {
                background-color: $white;
                padding: $micro $tiny;
            }

            tr:not(.table-title) th:first-of-type {
                border-right: 2px $black-300 solid;
            }
        }
    }
}
