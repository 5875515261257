@import 'stylesheets/variables';
.geo-type-list-item {
    button {
        justify-content: space-between;
        align-items: center;
        &:hover,
        &:focus {
            .geo-type-list-item-arrow {
                visibility: visible;
                color: $black-600;
            }
        }
        .label {
            display: flex;
            max-width: 100%;
            &-sumlev {
                padding-right: $micro;
            }
            &-indent {
                padding-right: $micro;
            }
            &-body {
                white-space: normal;
                word-break: break-word;
                text-align: left;
            }
        }
        .geo-type-list-item-arrow {
            visibility: hidden;
        }
    }
}
