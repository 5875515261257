@import 'stylesheets/variables';

#report-view {
    height: 100vh;
    display: flex;
    flex-flow: column;

    #report-page-content {
        padding-top: $nano;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        height: calc(100% - #{$header-height});
        background: $black-50;
        flex-flow: row-reverse;

        .svg-loader {
            width: 100%;
        }
    }
}
