@import 'stylesheets/variables';
$top-bottom-padding: $tiny;
$left-right-padding: $standard;

// determine what is line height of the button
// this value is needed to determine border radius
// #start
$button-line-height: 0;
@each $name, $font-size, $line-height in $typography-list {
    @if 'body-2' == $name {
        $button-line-height: $line-height;
    }
}
$button-line-height: if(
    $icon-size-standard > $button-line-height,
    $icon-size-standard,
    $button-line-height
);
// #end

.survey-filterable-by-text-list-button {
    @extend %body-2-black-600;
    display: flex;
    align-items: center;
    padding: $top-bottom-padding $left-right-padding;
    border: $border-black-300;
    border-radius: $tiny + $button-line-height;
    width: 100%;

    .survey-filterable-by-text-list-button-icon {
        margin-right: $small;
    }
}
