@import 'stylesheets/variables';

.user-menu-button {
    width: $sidebar-collapsed-width;
    display: flex;
    justify-content: center;
}

.user-actions-container {
    position: absolute;
    top: $semi + $standard;
    right: $micro;
    left: unset;
    width: $user-menu-width;
}
