@import 'stylesheets/variables';
.report-action-modal {
    padding: $small;
    .report-action-modal-content .multiline {
        flex-grow: 1;
    }
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: $semi;
    }
    &-footer {
        display: flex;
        justify-content: flex-end;
        padding-top: $small;
    }
}
