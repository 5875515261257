@import 'stylesheets/variables';

.citation-format-picker {
    display: flex;
    flex-direction: column;
    color: $black-900;

    button.button {
        font-size: 14px;
        font-weight: 600;
        color: $black-900;
        i.trigger-icon {
            color: $black-900;
        }
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: $tiny;
    }
    &__dropdown {
        margin-bottom: $tiny;
        .dropdown-menu {
            .dropdown-trigger {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $black-100;
                border-radius: $border-radius-large;
                border: 1px solid $black-200;
                padding: $micro $small;
                font-size: 15px;
                width: 100%;

                &:hover {
                    background-color: $black-250;
                }

                .trigger-icon {
                    margin-left: 0;
                }
            }
        }

        .citation-formats-dropdown {
            width: 100%;
            max-height: 300px;
            overflow: auto;
        }
    }
}

.citation-box {
    background: $black-100;
    border-radius: $nano;
    min-height: $x-large;
    max-height: $x-large * 5;
    padding: 0 $small;
    overflow-y: scroll;
    margin-bottom: $tiny;
    border: 1px solid $black-200;

    @extend %body-3-black-900;
}

.copy-link-button-container {
    display: flex;
    justify-content: flex-end;
}
