@import 'stylesheets/variables';

.survey-info-buttons-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;

    .survey-info-button {
        height: 40px;
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;

        @extend %button-small-primary;
        text-decoration: none;
    }

    .info-panel-divider {
        height: $small;
        margin: 0 $micro;
    }
}
