@import 'stylesheets/variables';
.found-list {
    &-header {
        padding: $standard;
    }
    &-selection-list {
        list-style: none;
        margin: 0;
        padding: $small $standard;
        overflow: hidden;
        overflow-y: auto;
        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $micro $small;
            .fips {
                font-weight: bold;
            }
            .label {
                padding-left: $small;
            }
            .icon {
                color: $primary-800;
            }
        }
    }
    &-warning {
        display: flex;
        padding: $small $standard;
        &-label {
            @extend %body-2-black-600;
            padding-left: $tiny;
        }
        &-icon {
            display: flex;
            background: $warning-background;
            border-radius: 50%;
            padding: $tiny * 0.5;
            i {
                color: $warning-color;
            }
            height: $icon-size-standard + $tiny;
            justify-content: center;
            align-items: center;
        }
        button {
            @extend %button-small-primary;
        }
    }
    &-actions {
        display: flex;
        justify-content: flex-end;
        padding: $small $standard $standard;
    }
}
