@import 'stylesheets/variables';
.selected-tables-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: $standard auto;
}

.selected-tables-list {
    width: 100%;
}

.selected-tables-header {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: $tiny;

    .selected-tables-clear-button {
        @extend %button-small-primary;
        margin: 0;
    }
    .selection-counter {
        @extend %body-3-black-600;
        padding-right: $small;
        padding-left: 0;
        border-right: $border;
    }
}
