@use "sass:math";

@import 'stylesheets/variables';
$checkbox-checked-color: $primary-800;
$checkbox-border-color: $black-600;
$checkbox-border-color-disabled: $black-600;
$checkbox-checked-color-disabled: $black-600;

$checkbox-margin: 1em 0;
$checkbox-size: 1.25em;
$checkbox-padding: 0.25em;
$checkbox-border-width: 0.125em;
$checkbox-border-radius: 0.125em;
$checkmark-width: 0.125em;
$checkmark-color: $white;
$checkbox-label-padding: 1.75em;
.labeled-checkbox {
    @extend %body-1-black-600;
    margin: $small 0;
    display: flex;
    flex-flow: row;
    position: relative;
    text-align: left;

    label {
        cursor: pointer;
        user-select: none;
        display: inline;
        line-height: $checkbox-size;
        vertical-align: top;
        clear: both;
        &:not(:empty) {
            padding-left: $checkbox-label-padding;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
        }

        &:before {
            // box
            width: $checkbox-size;
            height: $checkbox-size;
            background: $white;
            border: $checkbox-border-width solid $checkbox-border-color;
            border-radius: $checkbox-border-radius;
            cursor: pointer;
            transition: background 0.3s;
        }
    }

    input[type='checkbox'] {
        font-size: inherit;
        height: 100%;

        &:checked {
            + label:before {
                background: $checkbox-checked-color;
                border: none;
            }
            + label:after {
                $checkmark-size: $checkbox-size - 2 * $checkbox-padding;

                transform: translate(
                        $checkbox-padding,
                        ($checkbox-size * 0.5) - math.div($checkmark-size, 2.6)
                    )
                    rotate(-45deg);
                width: $checkmark-size;
                height: $checkmark-size * 0.5;

                border: $checkmark-width solid $checkmark-color;
                border-top-style: none;
                border-right-style: none;
            }
        }

        &:disabled {
            + label:before {
                border-color: $checkbox-border-color-disabled;
            }
            &:checked {
                + label:before {
                    background: $checkbox-checked-color-disabled;
                }
            }
        }
    }
}
