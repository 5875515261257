@import 'stylesheets/variables';
.premade-reports-header {
    display: flex;
    flex-direction: column;
    padding: $small;
    &-main {
        display: flex;
        justify-content: space-between;
        h4 {
            @extend %h4-black-900;
            display: flex;
            align-items: center;
            padding-left: $micro;
            margin: 0;
        }
    }
}
