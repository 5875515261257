@import 'stylesheets/variables';

.dropdown-menu {
    .dropdown-menu-popover {
        // This was not covered by design. I added this width on my own
        width: 2 * $semi;

        &.bottom-up-direction {
            bottom: 0;
            top: unset;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }
    }

    .dropdown-trigger {
        color: white;
        display: flex;
        align-items: center;
        padding: 0 $semi 0 $tiny;

        @media (max-width: $small-desktop) {
            padding: 0 $tiny;
        }

        .trigger-icon {
            color: white;
            margin-left: $micro;
        }
    }
}
