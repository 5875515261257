@import 'stylesheets/variables.scss';

.zoomable {
    display: flex;
    max-height: $zoomable-max-height;
    flex-direction: column;

    .zoomable-header {
        flex: 0 0 auto;
    }
    .zoomable-content {
        flex: 0 1 $zoomable-content-height;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .zoomable-actions {
        flex: 0 0 $zoomable-actions-height;
    }
}
