@import 'stylesheets/variables';

ul.filterable-by-text-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: $micro 0;
    flex: 1 1 100%;
    overflow: auto;

    .no-item {
        padding: $tiny $standard;
    }

    li {
        button {
            @extend %body-1-black-900;
            min-height: $large;
            padding: $micro $standard;
            width: 100%;
            align-items: center;

            &:hover,
            &:focus {
                background-color: $black-100;
            }
        }

        &.selected {
            button {
                @extend %body-1b-primary-800;
                background-color: $primary-100;
            }
        }

        &.divider {
            color: $black-600;
            font-size: 13px;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
            border-top: $border;
            padding: $tiny 0 $micro;
            margin: 0 $standard;
            min-height: $large;
        }
    }
}
