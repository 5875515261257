@import 'stylesheets/variables';
.premade-reports-selector-action {
    display: flex;
    justify-content: space-between;
    padding: $tiny $standard;
    &-count {
        @extend %body-2-black-600;
        display: flex;
        align-items: center;
    }
    &-actions {
        // important for some languages, like es
        // reference: https://socex-charts.atlassian.net/browse/SOCEX-1209
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }
}
