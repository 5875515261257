@import 'stylesheets/variables';

.inflation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
        line-height: unset;
    }

    button.button {
        color: $black-800;
        i.trigger-icon {
            color: $black-800;
        }
    }

    .dropdown-menu {
        .dropdown-trigger {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $black-100;
            border-radius: $border-radius-large;
            border: 1px solid $black-200;
            width: $sidebar-panel-dropdown-trigger-width;
            padding: $micro $small;
            &:hover {
                background-color: $black-250;
            }
        }
    }

    .allow-dollar-year-adjustment-dropdown {
        width: 100%;
        max-height: 300px;
        overflow: auto;
    }
}
