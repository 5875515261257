@import 'stylesheets/variables';
.geo-items-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $small $standard;
    border-top: $border;
    // important for some languages, like es
    // reference: https://socex-charts.atlassian.net/browse/SOCEX-1209
    &-actions {
        flex-shrink: 0;
    }
}
