@import 'stylesheets/variables';
.report-table table tbody tr.empty-row {
    position: relative;
    td {
        height: $tiny;
        line-height: $tiny;
        padding: 0;
        background-color: transparent;
        border: $table-border;
        &:first-of-type {
            position: sticky;
            left: 0;
            border-right: none;
        }
        &:last-of-type {
            border-left: none;
        }
    }
}
