@import 'stylesheets/variables';
.toggle-button-group {
    display: flex;

    .toggle-button {
        @extend %body-2-black-600;
        border-right: $border;
        border-top: $border;
        border-bottom: $border;
        font-weight: 600;
        // toggle buttons must be without margins (by design) so we can't
        // allow them to be overriden by other button styles which introduce
        // margins larger than 0
        margin: 0 !important;
        padding: 0 $small;
        max-width: 100px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline;
        white-space: nowrap;
        &:hover:not(.toggle-button-selected) {
            background-color: $primary-100;
        }
        &:first-child {
            border-top-left-radius: 22px;
            border-bottom-left-radius: 22px;
            border-left: $border;
        }
        &:last-child {
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
        }
        &-selected {
            max-width: 500px;
            color: $primary-800;
            background: $primary-100;
            border-color: $primary-100;
        }
    }
    @for $i from 2 through 7 {
        &.buttons-#{$i} {
            .toggle-button-selected {
                $offset: $i * 100;
                max-width: #{650px - $offset};
            }
        }
    }

    &.more-than-5-buttons {
        .toggle-button {
            max-width: 50px;
            padding: $nano;
            &-selected {
                max-width: 200px;
            }
        }
    }
}
