@import 'stylesheets/variables';
.modes-selector-group {
    display: flex;
    border-radius: $standard;
    border: $border-black-300;
    align-items: center;
    justify-content: space-between;
    margin-top: $semi;
    button.button {
        margin-right: 0;
    }
}
.modes-selector-button {
    flex-grow: 1;
    align-items: center;
    padding: $micro $standard $micro $small;
    span {
        @extend %body-2-black-900;
        padding-left: $tiny;
    }
    i {
        color: $primary-800;
    }
    &:not(:last-of-type) {
        border-right: $border;
    }
    &:hover,
    &:focus {
        background: $black-100;
    }
    &:first-child {
        border-top-left-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
    }
    &:last-child {
        border-top-right-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
    }
    &--disabled {
        cursor: default;
        pointer-events: none;
        span {
            color: $black-500;
        }
        i {
            color: $black-500;
        }
    }
}
