@import 'stylesheets/variables';

.selection-hint {
    margin-top: $large;
    height: $selection-hint-height;
    display: flex;
    flex-flow: row;

    p {
        margin: $large 0 0 $small;
        width: $selection-hint-text-width;
        @extend %body-1-black-600;
    }
}
