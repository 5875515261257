html {
    height: 100vh;
}
body,
#root {
    min-height: 100vh;

    &.hide-outline {
        *:focus {
            outline: none;
        }
    }
}
