@import 'stylesheets/variables';
.select-year-buttons {
    margin: 0 $standard $nano;
    .select-year-button {
        border-radius: 50%;
        padding: $micro;
        min-width: 0px;
        margin-right: $micro !important;
        margin-bottom: $micro !important;
        width: $large;
        height: $large;
    }
}
