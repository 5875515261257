@import 'stylesheets/variables';
.survey-group-toggle-item {
    border-top: $border;
}

.survey-group-toggle-button {
    width: 100%;
    justify-content: left;
    align-items: center;
    padding: $tiny $standard;
    .toggle-icon {
        margin-right: $small;
    }
}
