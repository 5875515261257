@import 'stylesheets/variables';

#modify-report-panel {
    .section {
        padding: $standard;
        border-bottom: $border-black-250;
        &:last-of-type {
            border-bottom: none;
        }
    }
}
