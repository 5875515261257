@import 'stylesheets/variables';
tr.table-title {
    position: relative;
    th,
    td {
        background-color: $table-title-background !important;
    }
    th {
        left: 0;
        white-space: nowrap;
        z-index: 1;
        text-align: left;
        border-left: $table-border;
        border-right: $table-border;

        .sticky-title {
            a {
                // because of the th border, we will add an additional 1px
                position: sticky;
                left: $tiny + 1;
                text-decoration: underline;
                @extend %body-2-black-900;
                font-weight: 600;
            }
        }
    }
}
