@import 'stylesheets/variables';

.select-template-hero-section {
    display: flex;
    flex-flow: column;
    margin-top: $standard;
    padding-bottom: $micro;
    @extend %h1-black-900;

    .title {
        display: flex;
        justify-content: center;
        padding-top: $large;
    }
    .survey-name {
        display: flex;
        justify-content: center;
        padding-top: $micro;
        padding-bottom: $small;
        @extend %body-1-black-900;
    }
    .comparability-years {
        display: flex;
        justify-content: center;
        padding-bottom: $small;
        @extend %body-2-black-600;
    }
}
