@import 'stylesheets/variables';

#download-excel-panel {
    display: flex;
    flex-flow: column;
    width: 100%;
    @extend %body-1-black-700;
    padding: $standard;

    .download-excel-button {
        color: $primary-800;
        height: $large;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: $small;
        background: $black-100;
        margin-top: $small;

        .download-text {
            @extend %button-small-primary;
            margin-left: $small;
        }
        &[disabled] {
            cursor: not-allowed;
        }
        &.inactive {
            color: $black-500;
            .download-text {
                color: $black-500;
            }
        }
    }

    .warning-container {
        .description {
            margin-bottom: $tiny;
        }
        button {
            background: none !important;
            border: none;
            padding: 0;
            color: $primary-900;
            cursor: pointer;
            font-weight: bold;
        }
    }
}
