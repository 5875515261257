@import 'stylesheets/variables';

.user-actions {
    display: flex;
    flex-flow: column;

    .user-info {
        padding: $standard;
        box-sizing: border-box;
        display: flex;
        flex-flow: row;
        border-bottom: $border;

        .large-image {
            width: $x-large;
            height: $x-large;
            margin-right: $small;
            border-radius: $nano;
        }

        .user-info-text {
            display: flex;
            flex-flow: column;

            .first-and-last-name {
                @extend %h4-black-900;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .user-email {
                @extend %body-3-black-600;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-top: $nano;
                display: flex;
                align-items: center;
            }

            .user-license-plan {
                @extend %body-3-black-600;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }
    }

    .link {
        display: flex;
        align-items: center;

        @extend %button-standard-black;
        color: #606d7b;
        padding: $small $standard;
        text-align: left;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: $black-900;
            background-color: $black-50;
        }
    }

    .sign-out {
        justify-content: left;
    }
}
