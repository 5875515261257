@import 'stylesheets/variables';
.header {
    background-color: $white;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;

    &.modify-mode {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    }
}

.header-content {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: $white;
    height: $header-height;
}

.header-content-left {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.header-content-right {
    display: flex;
    flex-flow: row;
}

.header-title {
    font-weight: 600;
    padding-left: 48px;
    padding-right: 20px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    height: 24px;
    line-height: 24px;
}

.header-subtitle {
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-weight: normal;
}

.header-close-button {
    padding-left: 24px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}
