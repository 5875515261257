@import-normalize;
@import 'stylesheets/variables';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    font-family: $font-style;
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

hr {
    margin: 0px;
    border: none;
    border-top: $border;
}

h1 {
    @extend %h1-black-900;
    margin: 0;
}

h4 {
    @extend %h4-black-900;
    margin: 0;
}

h5 {
    @extend %h5-black-900;
    margin: 0;
}
h6 {
    @extend %h5-black-700;
    margin: 0;
}
a {
    .material-icons {
        pointer-events: auto;
    }
}
