@import 'stylesheets/variables';
.navigator-parameter {
    @extend %body-2-black-900;

    background-color: $black-100;
    padding: $nano $small $nano $standard;
    border-radius: $nano;
    box-sizing: content-box;

    display: flex;
    flex-flow: row;
    align-items: center;
    height: $standard;
}
