@import 'stylesheets/variables';

.dropdown-menu-buttons-container {
    display: inline-flex;
    align-items: center;
    padding: 0 $micro;
    border: $border-black-300;
    border-radius: $standard;

    &:hover {
        background-color: $black-200;
        border-color: $transparent;
    }

    &.selected {
        color: $primary-900;
        background-color: $primary-100;
        border-color: $transparent;
    }

    .clear-button {
        margin-right: 0;
    }

    .select-button {
        @extend %body-2-black-900;
        padding: 0 !important;
        display: inline-flex;
        align-items: center;
        height: $semi;

        .label {
            width: 100%;
            text-align: left;
            padding: 0 $nano 0 $micro;

            max-width: $dropdown-menu-button-max-width;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .icon {
            color: $primary-800;
        }
    }
}
