@import 'stylesheets/variables';

.selected-list-item {
    @extend %body-1-black-900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $large;
    padding: 0 $small;
    background-color: $black-100;

    &:hover,
    &:focus,
    &:active {
        .button {
            .material-icons {
                opacity: 1;
                color: $black-600;
            }
        }
    }

    .button {
        padding: 0;
        .material-icons {
            font-size: $icon-size-small;
            opacity: 0;
        }
        &:hover,
        &:focus,
        &:active {
            .material-icons {
                opacity: 1 !important;
                color: $black-900 !important;
            }
        }
    }
}
