@import 'stylesheets/variables';

.modify-option {
    border: $border-black-200;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    align-items: flex-start;
    background-color: $black-100;
    padding: $small;
    margin-bottom: $micro;
    text-decoration: none;
    &.button:not(:last-of-type) {
        margin-right: 0;
    }
    &:hover {
        background-color: $black-250;
    }
    &:first-of-type {
        margin-top: $standard;
    }
    .highlight-icon {
        color: $primary-800;
    }
    .edit-icon {
        color: $black-600;
    }
    &-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 $tiny;
        font-size: 14px;
        h5 {
            line-height: unset;
            font-size: 14px;
        }
    }
    &-text-description {
        @extend %body-1-black-600;
        font-size: 14px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // substract the sidebar panel padding, the modify option padding, the
        // title margin anf the size of the icons, and additional $small and $nano
        max-width: $sidebar-panel-width - 2 * $standard - 2 * $small - 2 * $tiny -
            2 * $icon-size-small - $small - $nano;
        min-height: $standard;
    }
}
