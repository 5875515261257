@import 'stylesheets/variables';

.selection-counter {
    @extend %body-2-black-600;
    padding-left: $micro;

    &.active {
        @extend %body-2-primary-800;
    }
}
