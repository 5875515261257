@import 'stylesheets/variables';

.filterable-by-text {
    width: 100%;
    .input-text-section {
        flex: 0 0 $filter-list-search;
        position: relative;
        width: 100%;
        padding: $tiny $standard;
        display: flex;
        align-items: center;
        .search-icon {
            margin-right: $small;
        }
        input {
            @extend %body-2-black-900;
            width: 100%;
            border: none;
            padding: 0;
            background: transparent;
            &::placeholder {
                color: $black-600;
                opacity: 1;
            }
        }
    }
}
