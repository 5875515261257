@import 'stylesheets/variables';
.download-card {
    &-header {
        @extend %body-1-black-900;
        display: flex;
        justify-content: space-between;
        padding: $tiny;
    }
    &-actions {
        display: flex;
    }
}
