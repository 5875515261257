@import 'stylesheets/variables';

.variable-value {
    text-align: right;
    border-top: $table-border;
    border-right: $table-border-light;
}
.variable-percent {
    @extend %body-1-black-700;
    text-align: right;
    border-top: $table-border;
    border-right: $table-border;
}
