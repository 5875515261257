@import 'stylesheets/variables';

.trial-warning-link {
    display: inline;
    margin: 0;
    a {
        font-weight: bold;
        text-decoration: none;
        color: $black-600;
    }
}
