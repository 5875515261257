@import 'stylesheets/variables';

.parent-geo-item {
    margin: 0 $micro $micro 0;

    button {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: $content * 0.5;
    }

    .disabled-label {
        display: none;
        color: $black-500;
        // Last breadcrumb is disabled, doesn't have title/caption
        // and it's always the last one so we should give more width
        max-width: $content * 3 * 0.25;
    }

    .popover {
        min-width: 260px;
    }

    .no-selection {
        .label {
            font-weight: 600;
        }
    }

    .breadcrumb-separator {
        color: $black-500;
        margin-right: $micro;
        font-size: 15px;
    }

    &:last-child {
        button {
            color: $black-500;
            display: none;
        }
        .disabled-label {
            display: inline-block;
        }
    }

    &:first-child {
        .breadcrumb-separator {
            display: none;
        }
    }
}
