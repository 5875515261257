@import 'stylesheets/variables';

.show-more-tables {
    td {
        @extend %body-2-black-700;
        background: transparent !important;
        border-top: $table-border;
        padding: $small !important;
        position: sticky;
        left: 0;
    }
}
