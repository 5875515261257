@import 'stylesheets/variables';

.collapse-info-trigger-chart {
    position: relative;

    @extend %body-1b-black-900;

    border: $border;
    border-radius: 50%;
    width: $chart-size;
    height: $chart-size;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: $tiny;
    &-bar {
        position: absolute;
        background-color: $primary-50;
        height: 100%;
        left: 0;
        width: 50%;
    }
    &-match {
        border-color: $primary-300;
        .collapse-info-trigger-chart-bar {
            background-color: $primary-100;
        }
    }
}
