@import 'stylesheets/variables';
.no-cot-info {
    display: flex;
    justify-content: space-between;
}

.no-cot-info-popover {
    margin-right: $micro;
    .popover {
        position: absolute;
        top: $semi + $standard + $nano;
        right: 0;
        left: unset;
        width: $sidebar-panel-width - 2 * $standard;
    }
}
