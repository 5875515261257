@import 'stylesheets/variables';
.template-action-modal {
    max-width: $template-modal-width;
    .template-action-modal-content {
        padding: $semi $standard;
        .details {
            border-radius: $nano;
            border: $border;
            padding: $tiny $small;
            &-title {
                @extend %body-3-black-600;
                margin-bottom: $tiny;
            }
            &-number-of-tables {
                @extend %body-1b-black-900;
            }
            &-tables-description {
                @extend %body-3-black-600;
            }
        }
    }
    &-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: $small;
        background-color: $black-50;
        border-bottom: $border;
        color: $black-900;

        .close-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: $micro;
        }
        button,
        i {
            color: $black-600;
        }
        h4 {
            margin-top: $semi;
            text-align: center;
        }
    }
    &-footer {
        display: flex;
        justify-content: flex-end;
        padding: 0 $standard $standard $standard;
    }
}
