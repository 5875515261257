@import 'stylesheets/variables';

li.premade-reports-item {
    .premade-report-table-list {
        list-style: none;
        hr:last-of-type {
            display: none;
        }
        &-item {
            padding: $micro $micro $micro $small;
            display: flex;
            justify-content: space-between;
            pointer-events: none;
            @extend %body-1-black-600;
            font-style: italic;
            &-icon {
                visibility: hidden;
            }
        }
    }

    &.selected {
        .premade-report-table-list {
            background-color: $primary-100;
        }
    }
}
