@import 'stylesheets/variables';
.sub-header {
    th,
    td {
        background-color: $table-title-background !important;
        border-top: $table-border;
        border-right: $table-border;
        @extend %body-2-black-900;
    }
    th:first-of-type {
        position: sticky;
        left: 0;
        border-left: $table-border;
    }
}
