@import 'stylesheets/variables.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white-900;
    // make sure modals are rendered above table view and report header
    z-index: 4;
    .page-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .full-width {
            width: 100%;
        }
    }
}
