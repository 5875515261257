@import 'stylesheets/variables';
.aggregation-type {
    margin-bottom: $semi;
    .toggle-button-group {
        margin-top: $small;
        button {
            max-width: unset;
            width: 33%;
            height: $semi;
        }
    }
    &-description {
        @extend %body-1-black-600;
        font-size: 14px;
    }
}
