@import 'stylesheets/variables';

.report-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    height: $header-height;
    background: white;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    align-items: center;

    .report-header-content-right {
        display: flex;
        align-items: center;
    }
    .report-header-content-left {
        display: flex;
        align-items: center;

        .se-logo {
            margin: $standard;
        }
        .report-header-report-title {
            @extend %body-1-black-900;
            margin-left: $standard;
        }
    }
}
