@import 'stylesheets/variables';
.table-selector-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $tiny $standard;
    // important for some languages, like es
    // reference: https://socex-charts.atlassian.net/browse/SOCEX-1209
    &-actions {
        flex-shrink: 0;
    }
}
