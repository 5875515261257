@import 'stylesheets/variables';

.page-content > h1.survey-header-with-create-template-box {
    margin-top: $standard;
}

.create-template-message-container {
    background-color: $primary-100;
    p {
        @extend %body-1-black-900;
        margin: 0;
        padding: $small 0;
    }
}
