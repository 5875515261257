@import 'stylesheets/variables';
@import 'stylesheets/button-reset';

$button-vertical-padding: $micro;

%button {
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: $button-vertical-padding $small;
    border-radius: $border-radius;
    text-align: center;
}

@function get-button-height($variant) {
    @each $style-name, $font-size, $line-height in $button-typography-list {
        @if 'button-#{$variant}' == $style-name {
            @return $line-height + 2 * $button-vertical-padding;
        }
    }
    @error "Unsupported variant: #{$variant}";
}

.button {
    @extend %button-reset;
    cursor: pointer;
    display: inline-flex;

    &:not(:last-of-type) {
        margin-right: $nano;
    }

    &-contained {
        @extend %button;
        @extend %button-standard-white;
        background-color: $primary-800;
        min-width: $button-min-width;
        &[disabled] {
            @extend %button-standard-black;
            pointer-events: none;
            background-color: $black-200;
        }
        &:hover:not(disabled),
        &:focus:not(disabled) {
            background-color: $primary-700;
        }
        &.small {
            @extend %button-small-white;
            &[disabled] {
                @extend %button-small-black;
                color: $black-500;
            }
        }
    }

    &-flat {
        @extend %button;
        @extend %button-standard-primary;
        background-color: transparent;
        min-width: $button-min-width;
        &[disabled] {
            @extend %button-standard-black;
            pointer-events: none;
        }
        &:hover:not(disabled),
        &:focus:not(disabled) {
            background-color: $black-100;
        }
        &.small {
            @extend %button-small-primary;
            &[disabled] {
                @extend %button-small-black;
                color: $black-500;
            }
            &.contained {
                &.container-small {
                    padding: $contained-padding-small;
                    min-width: unset;
                    background-color: transparent;
                }
            }
        }
    }

    &-icon {
        border-radius: 50%;

        &[disabled] {
            color: $black-400;
            pointer-events: none;
        }

        &:hover:not(disabled),
        &:focus:not(disabled) {
            color: $black-900;
        }

        &.default {
            width: $jump-button-width;
            height: $jump-button-width;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: $black-100;
            }
        }

        &.flat {
            color: $black-600;

            &:hover:not(disabled),
            &:focus:not(disabled) {
                color: $black-900;
            }

            &.primary {
                color: $primary-800;

                &:hover:not(disabled),
                &:focus:not(disabled) {
                    color: $primary-900;
                }
            }
        }

        &.contained {
            color: $black-600;

            &:hover:not(disabled),
            &:focus:not(disabled) {
                color: $black-900;
                background-color: $black-100;
            }

            &.primary {
                color: $primary-800;

                &:hover:not(disabled),
                &:focus:not(disabled) {
                    color: $primary-900;
                    background-color: $primary-100;
                }
            }
        }

        &.small {
            .icon {
                font-size: $icon-size-small;
            }

            &.contained {
                &.container-small {
                    padding: $contained-padding-small;
                }
                &.container-medium {
                    padding: $contained-padding-medium;
                }
                &.container-large {
                    padding: $contained-padding-large;
                }
            }
        }
        &.standard {
            .icon {
                font-size: $icon-size-standard;
            }

            &.contained {
                &.container-small {
                    padding: $contained-padding-small;
                }
                &.container-medium {
                    padding: $contained-padding-standard-medium;
                }
                &.container-large {
                    padding: $contained-padding-standard-large;
                }
            }
        }

        &.light {
            color: $white;
            &:disabled {
                color: $white-600;
            }
            &:hover:not(disabled),
            &:focus:not(disabled) {
                background-color: $white-300;
            }
        }
    }
}
