@import 'stylesheets/variables';

.modify-survey-dropdown {
    &.popover-container {
        .modify-survey-trigger {
            box-shadow: $box-shadow-surface-3;
            background-color: $white;
        }
    }
    .popover {
        position: absolute;
        top: $semi + $standard + $nano;
        right: 0;
        left: unset;
        width: $sidebar-panel-width - 2 * $standard;
    }
}
