@import '../../../../stylesheets/variables';

.premade-reports-item-row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: $micro $standard;
    height: $large;
    cursor: pointer;

    &:hover {
        background-color: $black-100;
    }

    .toggle-button-and-label {
        display: flex;
        flex-flow: row;
        align-items: center;

        button.label,
        button.toggle-expand {
            // Background color for selected li.buttons is set in filterableByTextListItem.scss
            // It is rgba color (with 0.y transparency).
            // On the other hand, for PremadeReportsFilterableByTextListItemRow
            // we set that same color as the background color for container of two buttons.
            // When such case happens, two elements with the rgba(x,x,x,0.y) background color
            // which are one on top of another - their accumulated background color becomes: rgba(x,x,x,0.2y)
            // Therefore we need to unset buttons backgroundColor and leave only the backgroundColor of the container
            background-color: unset;
            &:hover &:focus {
                background-color: unset;
            }
        }

        .toggle-expand {
            height: $standard;
            width: $standard;
            padding: 0;
            color: $black-900;
        }

        .label {
            padding: 0;
        }
    }

    .selection-icon {
        color: $primary-800;
        visibility: hidden;
    }
}

li.premade-reports-item {
    &.selected {
        .premade-reports-item-row {
            background-color: $primary-100;
            z-index: 2000;
            .selection-icon {
                visibility: visible;
            }
        }
    }
}
