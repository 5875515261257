@import 'stylesheets/variables';

.surveys-list-item {
    border-top: $border-black-300;
    button {
        @extend %body-1-black-900;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: $tiny $standard;
        &:hover,
        &:focus {
            background-color: $black-100;
        }
        .survey-icon {
            margin-right: $small;
        }
        .tag {
            margin-right: $standard;
        }
    }
}
