@import 'stylesheets/variables';

.parameter-page-navigator {
    display: flex;
    align-items: center;
    margin-left: $standard;

    @media (max-width: $small-desktop) {
        margin-left: $small;
    }

    button {
        padding: 0;

        &:focus {
            &:disabled {
                color: $black-400;
            }
        }
    }
}
