@import 'stylesheets/variables';

.select-years-info {
    @extend %body-2-black-600;
    margin: $tiny $standard 0;
    a {
        text-decoration: none;
        @extend %body-2-primary-800;
        font-weight: 600;
    }
}
