@import 'stylesheets/variables';

.fips-codes-hint {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: $fips-codes-hint-top;
    left: $fips-codes-hint-left;

    p {
        width: $fips-codes-hint-text-width;
        margin-top: $standard;
        margin-left: $small;
        @extend %body-1-black-600;
    }
}
