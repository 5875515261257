$font-style: 'Source Sans Pro', sans-serif;

// black palette
$black: rgba(0, 0, 0, 1);
$black-50: rgba(0, 0, 0, 0.02);
$black-100: rgba(0, 0, 0, 0.04);
$black-200: rgba(0, 0, 0, 0.06);
$black-250: rgba(0, 0, 0, 0.08);
$black-300: rgba(0, 0, 0, 0.13);
$black-400: rgba(0, 0, 0, 0.26);
$black-500: rgba(0, 0, 0, 0.38);
$black-600: rgba(0, 0, 0, 0.54);
$black-700: rgba(0, 0, 0, 0.62);
$black-800: rgba(0, 0, 0, 0.74);
$black-900: rgba(0, 0, 0, 0.87);

// white palette
$white: rgba(255, 255, 255, 1);
$white-50: rgba(255, 255, 255, 0.02);
$white-100: rgba(255, 255, 255, 0.04);
$white-200: rgba(255, 255, 255, 0.06);
$white-300: rgba(255, 255, 255, 0.13);
$white-400: rgba(255, 255, 255, 0.26);
$white-500: rgba(255, 255, 255, 0.38);
$white-600: rgba(255, 255, 255, 0.54);
$white-700: rgba(255, 255, 255, 0.62);
$white-800: rgba(255, 255, 255, 0.74);
$white-900: rgba(255, 255, 255, 0.87);

// primary palette
$primary-50: var(--color-50);
$primary-100: var(--color-100);
$primary-200: var(--color-200);
$primary-300: var(--color-300);
$primary-400: var(--color-400);
$primary-500: var(--color-500);
$primary-600: var(--color-600);
$primary-700: var(--color-700);
$primary-800: var(--color-800);
$primary-900: var(--color-900);

// transparent
$transparent: rgba(0, 0, 0, 0);

$shadow-outline: rgba(0, 0, 0, 0.06) solid 20000px;
$border: 1px $black-300 solid;
$border-black-300: 1px $black-300 solid;
$border-black-250: 1px $black-250 solid;
$border-black-200: 1px $black-200 solid;
$border-transparent: 1px $transparent solid;

// spacing
$nano: 4px;
$micro: 8px;
$tiny: 12px;
$small: 16px;
$standard: 24px;
$semi: 32px;
$large: 48px;
$x-large: 64px;

// page content
$content: 680px;

// layout width steps
$small-desktop: 1280px;

// buttons
$button-min-width: 72px; // kaze ivona: google rekao
$border-radius: 2px;
$border-radius-large: 22px;
$jump-button-width: 40px;
$navigator-popover-button-width: 36px;

// typography
$colors-list-with-names: 'black' $black, 'black-50' $black-50,
    'black-100' $black-100, 'black-200' $black-200, 'black-300' $black-300,
    'black-400' $black-400, 'black-500' $black-500, 'black-600' $black-600,
    'black-700' $black-700, 'black-800' $black-800, 'black-900' $black-900,
    'white' $white, 'white-50' $white-50, 'white-100' $white-100,
    'white-200' $white-200, 'white-300' $white-300, 'white-400' $white-400,
    'white-500' $white-500, 'white-600' $white-600, 'white-700' $white-700,
    'white-800' $white-800, 'white-900' $white-900, 'primary' $primary-800,
    'primary-50' $primary-50, 'primary-100' $primary-100,
    'primary-200' $primary-200, 'primary-300' $primary-300,
    'primary-400' $primary-400, 'primary-500' $primary-500,
    'primary-600' $primary-600, 'primary-700' $primary-700,
    'primary-800' $primary-800, 'primary-900' $primary-900;
$typography-list: 'h1' 21px 30px 600, 'h4' 17px 22px 600, 'h5' 15px 24px 600,
    'body-1' 15px 24px normal, 'body-1b' 15px 24px 600,
    'body-2' 14px 22px normal, 'body-2b' 14px 22px 600, 'body-3' 13px 18px 400,
    'body-4' 12px 18px 400;
@each $name, $font-size, $line-height, $font-weight in $typography-list {
    @each $color-name, $color in $colors-list-with-names {
        %#{$name}-#{$color-name} {
            font-size: $font-size;
            line-height: $line-height;
            font-weight: $font-weight;
            color: $color;
        }
    }
}

$button-typography-list: 'button-standard' 15px 24px 600,
    'button-small' 14px 16px 600;
$button-colors-list: 'primary' $primary-800, 'white' $white, 'black' $black-400;

@each $name, $font-size, $line-height, $font-weight in $button-typography-list {
    @each $color-name, $color in $button-colors-list {
        %#{$name}-#{$color-name} {
            font-size: $font-size;
            line-height: $line-height;
            font-weight: $font-weight;
            color: $color;
        }
    }
}

$icon-size-small: 18px;
$icon-size-standard: 24px;

// header
$header-height: 56px;
$progress-bar-height: $nano;

// navigator
$navigator-width: 773px;
$navigator-height: 56px;

// sidebar
$sidebar-collapsed-width: $x-large + $micro;
$sidebar-panel-width: 420px;
$sidebar-expanded-width: $sidebar-collapsed-width + $sidebar-panel-width;
$sidebar-panel-dropdown-trigger-width: 160px;

// warning colors
$warning-color: #efa54b;
$warning-background: rgba(239, 165, 75, 0.12);

// upgrade banner
$upgrade-padding: 148px 0 0 $small;
$upgrade-gradient: linear-gradient(-180deg, $white-50 10%, $white 18%);

// help menu
$help-menu-width: 128px;

// hints
$fips-codes-hint-top: 76px;
$fips-codes-hint-left: 305px;
$fips-codes-hint-text-width: 160px;
$selection-hint-height: 120px;
$selection-hint-text-width: 244px;

// table view
$table-header-background: rgb(215, 228, 234);
$table-title-background: rgb(236, 239, 241);
$table-border: 1px #e4e4e4 solid;
$table-border-invert: 1px $white solid;
$table-border-light: 1px #f4f4f4 solid;
$table-row-hover-background: #f7f8f8;
$table-label-max-width: 335px;

// user menu
$user-menu-width: 320px;

// contained button icons
$contained-padding-small: 1px;
$contained-padding-medium: 4px;
$contained-padding-large: 15px;
$contained-padding-standard-medium: 4px;
$contained-padding-standard-large: 12px;

// zoomable
$zoomable-max-height: 90vh;
$zoomable-content-height: 450px;
$zoomable-actions-height: 65px;
$filter-list-search: 48px;

// inputs
$single-line-height: 54px;

// fips selector
$fips-selector-dropdown-width: 540px;

// collapse info
$chart-size: 48px;

// surface box shadows
$box-shadow-surface-2: 0px 2px 8px 0px rgba(96, 96, 96, 0.2);
$box-shadow-surface-3: 0 1px 16px 0 rgba(0, 0, 0, 0.2);

// dropdowns
$dropdown-menu-button-max-width: 534px;
$dropdown-menu-popover-width: 594px;
$dropdown-menu-popover-top: 45px;
$dropdown-parent-geo-item-list-max-height: 350px;

// save template
$template-modal-width: 420px;
