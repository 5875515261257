@import 'stylesheets/variables';
@for $i from 1 through 10 {
    .indent-#{$i} {
        $padding: $i * $small;
        padding-left: #{$padding};
    }
}
.variable-label {
    border-top: $table-border;
    border-right: $table-border;
    border-left: $table-border;
    min-width: 150px;
    max-width: $table-label-max-width;
    position: sticky;
    left: 0;
    text-align: left;
    font-weight: normal;
    &:after {
        box-shadow: 3px 0 5px -5px rgba(0, 0, 0, 1);
    }
    > span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
