@import 'stylesheets/variables';

$left-right-padding-in-cell: $tiny;
$top-bottom-padding-in-cell: $micro;

table.info-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    th,
    td {
        padding: $top-bottom-padding-in-cell $left-right-padding-in-cell;
        border: $border;
        @extend %body-1-black-900;
        &:first-of-type {
            border-left: none;
        }
        &:last-of-type {
            border-right: none;
        }
        &.label {
            position: relative;
            span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: $top-bottom-padding-in-cell $left-right-padding-in-cell;
            }
            min-width: 40%;
        }
        &.empty {
            background: repeating-linear-gradient(
                -33deg,
                transparent,
                transparent 5px,
                $black-200 5px,
                $black-200 6px
            );
        }
    }
    td.label:before {
        content: '&nbsp;';
        visibility: hidden;
    }
    span.new-survey {
        @extend %body-1b-primary-900;
    }
}
