@import 'stylesheets/variables';

.change-survey-title {
    margin-bottom: $standard;
    h1 {
        margin-top: $large;
        margin-bottom: $micro;
    }
    span {
        @extend %body-1-black-600;
    }
}
