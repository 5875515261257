@import 'src/components/Buttons/Button.scss';

button.with-icon-right {
    i {
        margin-left: $nano;
    }
}

.more-options-popover {
    // distance from the top of the button
    top: get-button-height('standard') + $small;
    left: unset;
    right: 0;
    width: unset;
    padding: $tiny 0;
    hr {
        margin: $micro 0;
    }
}
