@import 'stylesheets/variables';
.svg-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.svg-loader__text {
    color: $black-500;
    font-size: 16px;
    line-height: 24px;
}
.svg-loader__heading {
    /*color: @primary-dark-text-color;*/
    /*font-size: @h1-font-size;*/
    line-height: 27px;
    padding: 24px 0;
}
