@import 'stylesheets/variables';

.popover-container {
    position: relative;
    hr {
        border-top: $border-black-250;
    }
}

.popover {
    // popover should be rendered over table view
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
