@import 'stylesheets/variables';

.fips-codes-textarea-wrapper {
    position: relative;
    .fips-codes-textarea {
        display: flex;
        width: 100%;
        height: 400px;
        resize: none;
        border: none;
        padding: $standard;
        background: $black-50;
    }
    .fips-codes-textarea-clear {
        position: absolute;
        top: $standard;
        right: $standard;
        @extend %button-standard-black;
    }
}
