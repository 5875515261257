@import 'stylesheets/variables';

.surveys-filterable-by-text-list-item {
    button {
        display: flex;
        justify-content: left;
        .survey-icon {
            margin-right: $small;
        }
    }
}
