@import 'stylesheets/variables';
.header-next-button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-left: 16px;
    padding-right: 16px;
    background: $primary-700;
    border-radius: 2px;
    height: 40px;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 36px;

    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 600;
}

.header-next-button-disabled {
    color: rgba(0, 0, 0, 0.26);
    font-size: 15px;
    font-weight: 600;
}

.disabled-gray-button {
    background: rgba(0, 0, 0, 0.06);
    color: rgba(0, 0, 0, 0.26);
}
