@import 'stylesheets/variables';
.not-found {
    &-header {
        display: flex;
        align-items: center;
        padding: $standard $small 0;
    }
    &-list {
        &-wrapper {
            position: relative;
            .copy-codes {
                position: absolute;
                top: $standard;
                right: $standard;
                @extend %button-standard-primary;
            }
        }
        &-textarea {
            width: 100%;
            height: 300px;
            resize: none;
            border: none;
            padding: $standard;
            background: $black-50;
        }
        &-warning {
            display: flex;
            padding: $small $standard;
            &-label {
                @extend %body-2-black-600;
                padding-left: $tiny;
            }
            &-icon {
                display: flex;
                background: $warning-background;
                border-radius: 50%;
                padding: $tiny * 0.5;
                i {
                    color: $warning-color;
                }
                height: $icon-size-standard + $tiny;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
