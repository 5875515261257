@import 'stylesheets/variables';

.jump-button {
    position: absolute;
    left: -10000px;

    &.button-icon {
        width: $jump-button-width;
        height: $jump-button-width;
        align-items: center;
        justify-content: center;
    }
}

.jump-button:focus {
    position: relative;
    left: unset;
}
