@import '../../../stylesheets/variables';

.session-info {
    display: flex;
    flex-flow: row;
    margin-left: $small;
    align-items: center;
    margin-right: $nano;

    .licence-title {
        margin-right: $small;
    }
}
