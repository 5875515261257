@import 'stylesheets/variables';
.no-select-years-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $micro;
    margin-left: $standard;
    margin-right: $micro;
    margin-bottom: $standard;
}

.no-select-years-info {
    @extend %body-2-black-600;
    margin: $tiny $standard 0;
    a {
        text-decoration: none;
        @extend %body-2-primary-800;
        font-weight: 600;
    }
}
