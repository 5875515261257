@import 'stylesheets/variables';

$padding: $standard;
$content-width: $content;

.page-content {
    max-width: ($content-width + (2 * $padding));
    margin: 0 auto;
    padding-right: $padding;
    padding-left: $padding;
    padding-bottom: $padding;
    > h1 {
        // Sorry if this made your life hard. Please refactor (:
        margin-top: $large;
        margin-bottom: $semi;
    }
}
