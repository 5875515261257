@import 'stylesheets/variables';

.change-survey-match {
    display: flex;
    padding: $small;
    margin-top: $small;
    margin-bottom: $small;
    .match-icon {
        display: flex;
        border-radius: 50%;
        height: $icon-size-standard;
        justify-content: center;
        align-items: center;
        i {
            color: $primary-800;
            font-size: $small;
        }
    }
    span {
        padding: 0 $nano 0 $tiny;
        @extend %body-2-black-800;
    }
}
