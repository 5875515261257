@import 'stylesheets/variables';

#sidebar-container {
    display: flex;
    flex-flow: row;
    max-height: 100%;
    z-index: 2;

    .sidebar-icons {
        display: flex;
        flex-flow: column;
        border-left: $border;
        align-items: center;

        .sidebar-icons-top-divider {
            height: $micro;
            width: 100%;
        }
    }
}
