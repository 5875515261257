@import 'stylesheets/variables';

#citation-panel {
    @extend %body-1-black-700;
    padding: $standard;

    .disclaimer {
        font-size: 14px;
        line-height: 22px;

        &__title {
            font-weight: 600;
        }

        &__content {
            font-weight: normal;
        }
    }
}
