@import 'stylesheets/variables';

.sidebar-panel {
    width: $sidebar-panel-width;
    margin-top: $micro;
    display: flex;
    flex-flow: column;

    .sidebar-panel-header {
        display: flex;
        flex-flow: column;
        border-bottom: $border-black-250;
    }

    .sidebar-panel-header-container {
        box-sizing: border-box;
        height: $x-large;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 $small 0 $standard;

        .sidebar-panel-title {
            display: flex;
        }
    }

    .sidebar-panel-tab-controls {
        display: flex;
        flex-flow: row;
        padding: 0 $standard;

        .sidebar-panel-tab-button {
            color: $primary-800;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            border-bottom-color: $white;
            border-radius: 0;
            margin: 0;
            font-size: 14px;
            padding: $micro - 3px $micro;
            margin-right: $nano;
            min-width: $x-large;

            &:hover,
            &.active {
                border-bottom-color: $primary-800;
            }

            &:hover,
            &:active {
                background: none;
            }
        }
    }

    .sidebar-panel-content {
        overflow-y: auto;
        height: 100%;
    }
}
