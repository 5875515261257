@import 'stylesheets/variables';
.border-wrapper {
    display: flex;
    border: 1px solid $black-300;
    border-radius: $nano;
    height: $single-line-height;
    margin-bottom: $small;
}
.input-wrapper {
    display: flex;
    position: relative;
    margin: $small 0 0;
    width: 100%;
    .input-label {
        display: block;
        position: absolute;
        top: 0;
        left: $small;
        pointer-events: none;
        margin-top: $nano;
        transition: all 250ms;
        color: $black-600;
        font-size: 1rem;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        border: 0;
        border-bottom: 2px solid transparent;
        outline: none;
        margin-bottom: 2px;
        background-color: $white;
        transition: all 250ms, border-bottom-color 0s;
        height: 100%;
        padding: $small;
        border-radius: $nano;
    }

    textarea {
        overflow: hidden;
        resize: none;
    }

    input,
    textarea {
        &::placeholder {
            color: transparent;
        }
        &:focus::placeholder {
            color: inherit;
        }
    }
    &:hover:not(:focus-within):not(.has-content) {
        input,
        textarea {
            border-bottom: 2px solid $black-300;
            margin-bottom: 0;
        }
    }
    &.has-content,
    &:focus-within {
        .input-label {
            margin-top: -$tiny;
            font-size: 0.9rem;
            color: $primary-800;
        }
    }
    &:focus-within {
        input,
        textarea {
            border-bottom: 2px solid $primary-800;
            margin-bottom: 0;
        }
    }
}
