@import 'stylesheets/variables';

.collapse {
    border-top: $border;

    &.with-bottom-border {
        border-bottom: $border;
    }

    .title {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        height: $x-large - $nano - ($nano * 2);
        width: 100%;

        padding: 0;
        span {
            @extend %h5-black-900;
        }

        i {
            color: $black-600;
        }
    }

    .options {
        margin-bottom: $small;
    }
}
