@import 'stylesheets/variables';
.progress-bar-container {
    width: 100%;
    height: $progress-bar-height;
    background: $black-100;
    display: flex;
    justify-content: left;

    .progress-bar-fill {
        background: $primary-800;
        border-radius: 0px 100px 100px 0px;

        &.one-third {
            width: 72px;
        }
        &.two-thirds {
            width: calc(40% - #{$x-large});
        }
        &.three-thirds {
            width: calc(70% - #{$x-large});
        }
    }
}
